<template>
  <div class="container">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card
        elevation="2"
        outlined
        shaped
        tile
        style="max-width: 100%;width:500px;"
      >
        <v-card-text>
          <div>
            <div class="text-center">
              <img
                class="mb-4"
                src="@/assets/logo.png"
                alt=""
                style="width:300px"
              />
            </div>
            <h4>Recuperar contraseña</h4>
            <h5>
              Ingrese el email utilizado para el registro para restablecer su
              contraseña.
            </h5>

            <v-text-field
              v-model="email"
              v-bind:label="$t('salesforce.email')"
              v-bind:placeholder="`Nombre de usuario`"
              required
              :rules="emailRules"
            ></v-text-field>

            <div class="checkbox mb-3"></div>

            <v-btn color="blue darken-1" block @click="onSendEmail(email)">
              {{ $t("login.continue") }}
            </v-btn>

            <p class="mt-1 text-sm-center small">
              &copy; Trencadis Business Solutions, SL
            </p>
            <div class="text-right">
              {{ $t("login.try_login_again") }}
              <router-link to="/auth/login">{{
                $t("login.click_here")
              }}</router-link>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import ApiService from "@/services/apiService";
import { logInfo, logError } from "@/utils";

export default {
  name: "Forgotpassword",
  data() {
    return {
      valid: true,
      email: null,
      emailRules: [
        v => !!v || "El campo de email es obligatorio",
        v =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "El email proporcionado debe ser una dirección de email válida"
      ]
    };
  },

  methods: {
    async onSendEmail(email) {
      let self = this;
      if (self.$refs.form.validate()) {
        try {
          const response = await ApiService.get("auth/resetpassword/" + email);
          if (response && response.success) {
            logInfo("Por favor revise su correo electrónico.");
            this.$router.push({ name: "login" });
          } else {
            logError("El correo no existe.");
          }
        } catch (error) {
          console.log(error);
        }
      }
    }
  },

  mounted() {
    // let self = this;
    // window.addEventListener("keyup", function(event) {
    //   if (event.keyCode === 13) {
    //     self.onSendEmail(self.email);
    //   }
    // });
  }
};
</script>

<style scoped>
.container {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>
